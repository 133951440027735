import React, { ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Box, Heading, Text, Grid as BaseGrid, Anchor } from "grommet"
import VideoPlayer from "@components/LazyVideoPlayer"
import SanityImage, { builder } from "gatsby-plugin-sanity-image"
import { PortableText } from "@portabletext/react"

import Section from "@components/Section"
import Layout from "@components/Layout"
import ProportionalBox from "@components/ProportionalBox"

// prettier-ignore
const Grid = styled(BaseGrid)`
  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px) {
    grid-template-columns: minmax(480px, 1fr) 1fr;
    grid-template-rows: 3fr 9fr;
    grid-column-gap: 34px;
    align-items: start;
  }
`

// prettier-ignore
const GridPlayerItem = styled(Box)`
  order: 2;
  margin-bottom: ${({ theme }) => theme?.global?.edgeSize?.xsmall};

  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px) {
    grid-row-start: 1;
    grid-row-end: span 2;

    order:1;
    margin-bottom:0px;
  }  
`

// prettier-ignore
const GridBranding = styled(Box)`
  order: 1;
  margin-bottom: ${({ theme }) => theme?.global?.edgeSize?.xxsmall};

  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px) {
    order: 2;
    margin-bottom: 0px;
  }
`

const GridCopy = styled(Box)`
  order: 3;
`

// prettier-ignore
const CopyBox = styled(Box)`
  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px){
    max-width: 543px;  
  }
`;

type TProps = {
  children: ReactNode
}

const brandingPortableTextComponents = {
  types: {
    image: ({ value }: any) =>
      value ? (
        <SanityImage
          {...value}
          style={{
            height: value?.asset?.height,
            width: value?.asset?.width,
            objectFit: "cover",
          }}
          alt="Branding"
        />
      ) : null,
  },
  marks: {
    link: ({ children, value }: any) => {
      return (
        <Anchor href={value?.href} target="_blank">
          {children}
        </Anchor>
      )
    },
  },
}

const EpisodeLayout = ({ children }: TProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteContentQuery {
      sanitySite {
        _id
        id
        homeTitle
        homeVideoId
        homeVideoTitle
        homeVideoPlaceholder {
          ...ImageWithPreview
        }
        _rawHomeBranding(resolveReferences: { maxDepth: 2 })
        _rawHomeBody
      }
    }
  `)
  const site = data?.sanitySite
  const poster =
    builder.image(site?.homeVideoPlaceholder).width(700).url() ?? ""
  return (
    <Layout>
      <Section pad={{ vertical: "large" }} horPad>
        <Grid>
          <GridPlayerItem background="#000000">
            {site?.homeVideoId ? (
              <ProportionalBox w={16} h={9}>
                <VideoPlayer
                  hlsURL={`https://stream.mux.com/${site?.homeVideoId}.m3u8`}
                  poster={poster}
                />
              </ProportionalBox>
            ) : (
              <ProportionalBox w={16} h={9}>
                {site?.homeVideoPlaceholder && (
                  <SanityImage
                    {...site?.homeVideoPlaceholder}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt="Video Placeholder"
                    width={720}
                  />
                )}
              </ProportionalBox>
            )}
          </GridPlayerItem>

          <GridBranding>
            <Text size="14px">
              <Box direction="row" justify="start" align="baseline">
                <PortableText
                  value={site?._rawHomeBranding as any}
                  components={brandingPortableTextComponents}
                />
              </Box>
            </Text>
          </GridBranding>

          <GridCopy>
            <Heading
              level={1}
              size="small"
              margin={{ bottom: "xsmall" }}
              className="secondary-font"
            >
              {site?.homeTitle}
            </Heading>
            <CopyBox>
              <Text weight={400} size="small">
                <PortableText value={site?._rawHomeBody as any} />
              </Text>
            </CopyBox>
          </GridCopy>
        </Grid>
      </Section>
      {children}
    </Layout>
  )
}

export default EpisodeLayout
