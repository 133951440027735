import { darken, lighten } from "polished"
import { css, FlattenSimpleInterpolation } from "styled-components"

export default {
  global: {
    "tint-light": 0.075,
    "tint-dark": 0.075,
    colors: {
      brand: "#728572",

      "accent-1": "#CCC692",
      "accent-2": "#88C7E2",
      "accent-3": "#EBC882",
      "accent-4": "#E8ACBF",
      "neutral-1": "#99DBCE",
      "neutral-2": "#EBC882",
      "neutral-3": "#A1CAD1",
      "neutral-4": "#88C7E2",
      "neutral-5": "#E8ACBF",
      "neutral-6": "#E4A29E",
      "neutral-7": "#C3C682",
      "neutral-8": "#B7A1CC",

      "grey-1": { light: "#FBFBFB", dark: "#1C1C1C" },
      "grey-2": { light: "#F9F9F9", dark: "#282828" },
      "grey-3": { light: "#EFEFEF", dark: "#343434" },
      "grey-4": { light: "#CBCBCB", dark: "#4A4A4A" },
      "grey-5": { light: "#BEBEBE", dark: "#6E6E6E" },
      "grey-6": { light: "#929292", dark: "#929292" },
      "grey-7": { light: "#6E6E6E", dark: "#BEBEBE" },
      "grey-8": { light: "#4A4A4A", dark: "#E0E0E0" },
      "grey-9": { light: "#343434", dark: "#EFEFEF" },
      "grey-10": { light: "#282828", dark: "#F9F9F9" },
      "grey-11": { light: "#1C1C1C", dark: "#FBFBFB" },
      "grey-12": { light: "#000000", dark: "#FFFFFF" },

      "status-critical": "#CB3A3A",
      "status-warning": "#D39F00",
      "status-ok": "#00A042",

      background: {
        dark: "#282828",
        light: "#FFFFFF",
      },
      "background-front": {
        dark: "#343434",
        light: "#EFEFEF",
      },
      "background-back": {
        dark: "#4A4A4A",
        light: "#F5F5F5",
      },
      "background-contrast": {
        light: "#33333310",
        dark: "#FFFFFF18",
      },
      border: {
        dark: "#BEBEBE",
        light: "#6E6E6E",
      },

      control: {
        dark: "#ECECEC",
        light: "#728572",
      },
      "control-text": { dark: "#4A4A4A", light: "#F8F8F8" },
      "control-text-weak": {
        dark: lighten(0.2, "#4A4A4A"),
        light: darken(0.2, "#F8F8F8"),
      },
      secondary: "#adadad",
      "secondary-active": darken(0.2, "#adadad"),
      "secondary-hover": darken(0.1, "#adadad"),
      active: {
        light: "#E0E0E0",
        dark: "#4A4A4A",
      },
      hover: {
        dark: darken(0.1, "#ECECEC"),
        light: darken(0.1, "#4A4A4A"),
      },

      text: { light: "#343434", dark: "#EFEFEF" },
      "text-weak": { light: "#929292", dark: "#929292" },
      "text-strong": {
        dark: lighten(0.3, "#F8F8F8"),
        light: darken(0.3, "#444444"),
      },
      heading: {
        dark: "#F8F8F8",
        light: "#4A4A4A",
      },

      focus: {
        dark: "rgba(239, 239, 239, 0.5)",
        light: "rgb(52, 52, 52, 0.5)",
      },
      placeholder: { light: "#6E6E6E", dark: "#BEBEBE" },
    },
    font: {
      family: "DM Sans, Helvetica, Arial, sans-serif;",
      secondary: "linotype-sabon, Helvetica, Arial, sans-serif;",
      height: "24px",
      weight: 400,
      size: "16px",
    },
    edgeSize: {
      responsiveBreakpoint: "small",
      xlarge: "64px",
      large: "48px",
      medium: "36px",
      small: "24px",
      xsmall: "16px",
      xxsmall: "8px",
      hair: "1px",
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: "4px",
      large: "12px",
      xlarge: "24px",
    },
    breakpoints: {
      small: {
        value: 1000,
        edgeSize: {
          xlarge: "48px",
          large: "36px",
          medium: "24px",
          small: "16px",
          xsmall: "8px",
          xxsmall: "4px",
          hair: "1px",
        },
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: "4px",
          large: "6px",
          xlarge: "12px",
        },
      },
      medium: {
        value: 1260,
      },
      large: {
        value: 1440,
      },
      xlarge: {
        value: 1750,
      },
    },
    elevation: {
      light: {
        none: "none",
        xsmall: "2px 2px 1px rgba(0, 0, 0, 0.20)",
        small: "0px 2px 4px rgba(0, 0, 0, 0.20)",
        medium: "0px 4px 8px rgba(0, 0, 0, 0.20)",
        large: "0px 8px 16px rgba(0, 0, 0, 0.20)",
        xlarge: "0px 12px 24px rgba(0, 0, 0, 0.20)",
        glow: "0 0 14px 0 rgba(0,0,0,0.24)",
      },
      dark: {
        none: "none",
        xsmall: "2px 2px 1px rgba(0,0,0,0.25)",
        small: "0px 4px 4px rgba(0,0,0,0.25)",
        medium: "0px 6px 8px rgba(0,0,0,0.25)",
        large: "0px 8px 16px rgba(0,0,0,0.25)",
        xlarge: "0px 12px 24px rgba(0,0,0,0.25)",
        glow: "0 0 14px 0 rgba(0,0,0,0.24)",
      },
    },

    focus: {
      // shadow or outline are required for accessibility
      border: {
        // remove to only have shadow
        color: undefined,
      },
      // outline: { color: 'focus', size: '1px' },
      shadow: {
        color: "focus",
        size: "1px",
      },
    },

    control: {
      border: {
        width: "1px",
        radius: "6px",
        color: { light: "#929292", dark: "#929292" },
      },
      disabled: {
        opacity: 0.5,
      },
    },

    input: {
      padding: {
        horizontal: "11px",
        top: "16px",
        bottom: "16px",
      },
      font: {
        // size: undefined,
        // height: undefined,
        weight: 400,
      },
      tip: {
        offset: "5px",
      },
      icon: {
        padding: {
          left: "48px",
          right: "48px",
        },
      },
      error: {
        top: "6px",
        padding: {
          top: "24px",
          bottom: "8px",
        },
      },
    },

    drop: {
      // intelligentMargin: undefined,
      background: "background",
      border: {
        radius: "4px",
      },
      // margin: undefined
      // shadowSize: 'small', // shadowSize is deprecated, use 'elevation'
      elevation: "small",
      zIndex: "200",
      // extend: ({ alignProp }: TDropThemableProps): FlattenSimpleInterpolation =>
      //   css`
      //     transform-origin: ${getTransformOriginStyle(alignProp)};
      //   `,
    },
  },
  box: {
    responsiveBreakpoint: "small", // when we switch rows to columns
    extend: (): FlattenSimpleInterpolation => css`
      min-height: auto;
    `,
    border: {
      radius: {
        xsmall: "2px",
        small: "3px",
        medium: "4px",
        large: "6px",
        xlarge: "8px",
        full: "50%",
      },
    },
  },
  heading: {
    extend: ({ className }: any): FlattenSimpleInterpolation =>
      className?.includes("secondary-font") &&
      css`
        font-family: ${({ theme }: { theme: any }) =>
          theme?.global?.font?.secondary};
      `,
    weight: 500,
    color: "heading",
    // responsiveBreakpoint: 'small',
    font: {
      family: "DM Sans, Helvetica, Arial, sans-serif;",
    },
    level: {
      1: {
        // font: {},
        xlarge: {
          size: "114px",
          height: "120px",
          maxWidth: "2736px",
        },
        // large: { ...fontSizing(16) },
        medium: { size: "56px", height: "66px", maxWidth: "1190px" },
        small: { size: "48px", height: "46px" },
      },
      "2": {
        xlarge: {
          size: "64px",
        },
        large: {
          size: "46px",
          height: "60px",
        },
        medium: {
          size: "36px",
          height: "43px",
          maxWidth: "1140",
        },
        small: {
          size: "32px",
          height: "35px",
          maxWidth: "650px",
        },
      },
      "3": {
        large: {
          size: "36px",
          height: "47px",
        },
        medium: {
          size: "24px",
        },
      },
    },
  },
  text: {
    // extend: ({ weight }: TextExtendedProps): FlattenSimpleInterpolation => css`
    //   font-weight: ${weight ?? 300};
    // `,
    extend: ({ className }: any): FlattenSimpleInterpolation =>
      className?.includes("secondary-font") &&
      css`
        font-family: ${({ theme }: { theme: any }) =>
          theme?.global?.font?.secondary};
      `,
    font: {
      family: "DM Sans, Helvetica, Arial, sans-serif;",
    },
    "6xl": {
      size: "144px",
      height: "164px",
    },
    "5xl": {
      size: "120px",
      height: "150px",
    },
    "4xl": {
      size: "96px",
      height: "116px",
    },
    "3xl": {
      size: "72px",
      height: "82px",
    },
    "2xl": {
      size: "48px",
      height: "58px",
    },
    xxlarge: {
      size: "36px",
      height: "46px",
    },
    xlarge: {
      size: "28px",
      mobileSize: "22px",
      height: "30px",
      mobileHeight: "28px",
    },
    large: {
      size: "26px",
      mobileSize: "20px",
      height: "34px",
      mobileHeight: "28px",
    },
    medium: {
      size: "18px",
      mobileSize: "15px",
      height: "24px",
    },
    small: {
      size: "16px",
      mobileSize: "13px",
      height: "24px",
    },
    xsmall: {
      size: "14px",
      mobileSize: "12px",
      height: "16px",
    },
  },
  paragraph: {
    extend: (): FlattenSimpleInterpolation => css`
      font-weight: 400;
    `,

    font: {
      family: "DM Sans, Helvetica, Arial, sans-serif;",
    },
    xxlarge: {
      size: "36px",
      height: "46px",
      maxWidth: undefined,
    },

    xlarge: { size: "30px", height: "40px", maxWidth: undefined },
    large: { size: "24px", height: "32px", maxWidth: undefined },
    medium: { size: "18px", height: "26px", maxWidth: undefined },
    small: { size: "16px", height: "22px", maxWidth: undefined },
  },
  anchor: {
    textDecoration: "underline",
    fontWeight: 400,
    color: "text",
    hover: {
      textDecoration: "none",
      // fontWeight: undefined,
      // extend: undefined,
    },
    // extend: undefined,
  },
  button: {
    border: {
      radius: "30px",
    },
    size: {
      small: {
        border: {
          radius: "30px",
        },
        pad: {
          horizontal: "17px",
          vertical: "7px",
        },
      },
      medium: {
        border: {
          radius: "30px",
        },
        pad: {
          horizontal: "18px",
          vertical: "8px",
        },
      },
      large: {
        border: {
          radius: "30px",
        },
        pad: {
          horizontal: "22px",
          vertical: "18px",
        },
      },
    },
    default: {
      color: "control-text",
      background: {
        color: "control",
      },
      font: { weight: 500 },
    },
    primary: {
      color: "control-text",
      background: {
        color: "control",
      },
      font: { weight: 500 },
    },
    secondary: {
      color: "white",
      background: {
        color: "secondary",
      },
      font: { weight: 500 },
    },
    active: {
      color: "control-text",
      background: { color: "active" },
      secondary: {
        color: "white",
        background: {
          color: "secondary-active",
        },
      },
    },
    disabled: {
      opacity: 0.5,
      color: "control-text-weak",
      background: {
        color: "control",
      },
      secondary: {
        opacity: 0.5,
        color: "text-weak",
        background: {
          color: "grey-3",
        },
      },
    },
    hover: {
      color: "control-text",
      background: {
        color: "hover",
      },
      primary: {
        color: "control-text",
        background: {
          color: "hover",
        },
      },
      secondary: {
        color: "white",
        background: {
          color: "secondary-hover",
        },
      },
    },
  },
  icon: {
    size: {
      large: "28px",
      medium: "22px",
      small: "20px",
    },
  },
  list: {
    item: {
      border: undefined,
      pad: {
        horizontal: "small",
        vertical: "xsmall",
      },
    },
  },
  avatar: {
    // extend: undefined,
    size: {
      xsmall: "18px",
      small: "24px",
      medium: "48px",
      large: "72px",
      xlarge: "96px",
      // '2xl': `${baseSpacing * 5}px`, // 120px
      // '3xl': `${baseSpacing * 6}px`, // 144px
      // '4xl': `${baseSpacing * 7}px`, // 168px
      // '5xl': `${baseSpacing * 8}px`, // 192px
    },
    // text: {
    //   size: {
    //     xsmall: 'small', // 14px
    //     small: 'medium', // 18px
    //     medium: 'large', // 22px
    //     large: 'xlarge', // 26px
    //     xlarge: 'xxlarge', // 34px
    //     '2xl': '3xl', // 42px
    //     '3xl': '4xl', // 54px
    //     '4xl': '5xl', // 70px
    //     '5xl': '6xl', // 90px
    //   },
    //   // fontWeight: undefined,
    //   // extend: undefined
    // },
  },
  carousel: {
    icons: {
      color: "light-5",
    },
  },

  checkBox: {
    border: {
      color: "grey-6",
      width: "1px",
    },
    // label: {
    //   align: 'center',
    // },
    color: "#fbfbfb", // checked icon color (inc. toggle)
    // gap: undefined
    hover: undefined,
    check: {
      thickness: "2px",
      // extend: ({
      //   checked,
      //   indeterminate,
      //   // disabled,
      //   theme,
      // }: CheckboxThemableTProps): FlattenSimpleInterpolation => css`
      //   background: ${checked || indeterminate ? getGlobalColor(theme, 'grey-6') : 'none'};
      //   border-color: ${getGlobalColor(theme, 'grey-6')};
      // `,
    },
    icon: {
      size: "22px",
      extend: (): FlattenSimpleInterpolation => css`
        margin: -2px;
      `,
    },
    icons: {
      // checked: undefined,
      // indeterminate: undefined,
    },
    // pad: undefined,
    size: "20px",
    gap: "xsmall",
    // extend: ({ toggle, theme }: CheckboxThemableTProps): FlattenSimpleInterpolation | undefined => {
    //   const totalWidth = theme?.checkBox?.toggle?.size ?? '';
    //   const knobWidth = theme?.checkBox?.size ?? '';

    //   return toggle
    //     ? css`
    //         & input + span > span {
    //           top: 2px;
    //           left: 2px;
    //         }

    //         & input:checked + span > span {
    //           top: 2px;
    //           left: calc(${totalWidth} - ${knobWidth} - 2px);
    //         }
    //       `
    //     : undefined;
    // },
    toggle: {
      background: "#bebebe",
      color: "white",
      // knob: {
      //   extend: undefined,
      // },
      // radius: '24px', // `${baseSpacing}px`,
      // size: '48px', // `${baseSpacing * 2}px`,
      // extend: ({
      //   toggle,
      //   checked,
      // }: CheckboxThemableTProps): FlattenSimpleInterpolation | undefined =>
      //   toggle
      //     ? css`
      //         height: 24px;
      //         border: none;
      //         background: ${checked && '#6E6E6E'};
      //       `
      //     : undefined,
    },
  },
  radioButton: {
    border: {
      color: "grey-6",
      width: "1px",
    },
    color: "grey-6", // checked border color
    check: {
      // radius: '100%',
      // background: {
      //  color: undefined,
      // },
      color: "grey-6",
      // extend: undefined,
    },
    hover: {
      // background: {
      //   color: undefined,
      // },
      border: {
        color: "grey-6",
      },
    },
    icon: {
      size: "16px",
      // extend: undefined,
    },
    // icons: {
    //   circle: undefined,
    // },
    // gap: 'small',
    size: "20px",
    // font: {
    //   weight: undefined,
    // },
    // container: {
    //   extend: undefined
    // },
  },
  accordion: {
    icons: {
      collapse: undefined,
      expand: undefined,
    },
  },
  layer: {
    background: "grey-1",
    border: {
      radius: "4px",
      // intelligentRounding: undefined,
    },
    container: {
      elevation: "glow",
      zIndex: "20",
      extend: (): FlattenSimpleInterpolation =>
        css`
          overflow: hidden;
        `,
    },
    // extend: undefined,
    overlay: {
      background: "rgba(0, 0, 0, 0.4)",
    },
    responsiveBreakpoint: "small", // when Layer takes over the full screen
    zIndex: "300",
  },
}
