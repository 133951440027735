/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { ReactNode } from "react"
import styled from "styled-components"
import { Anchor, Box, Text } from "grommet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "@components/Header"
import Svg from "@components/Svg"
import {
  YoutubeIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
} from "@icons/index"

// prettier-ignore
const Footer = styled(Box)`
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px){
    flex-direction: row;
  }
`

const Container = styled(Box)`
  min-height: 100vh;
`

const Divider = styled.span`
  margin: 0 5px;
`;

type TProps = {
  children: ReactNode
}

const Layout = ({ children }: TProps): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      sanitySite {
        _id
        id
        instagram
        twitter
        youtube
        facebook
        logo {
          ...ImageWithPreview
        }
        headerUrl
      }
    }
  `)

  return (
    <Container fill background="background" justify="between">
      <Header logo={data?.sanitySite?.logo} url={data?.headerUrl} />

      <Box flex>
        <Box flex as="main">
          {children}
        </Box>

        <Box
          as="footer"
          pad={{ vertical: "xlarge" }}
          background="accent-1"
          className="no-print"
        >
          <Box className="hor-section-pad">
            <Footer gap="xsmall">
              <Text size="small">
                © {new Date().getFullYear()}{" "}River Cottage
                <Divider>|</Divider>
                <Anchor href="https://www.rivercottage.net/contact-us" target="_blank">
                  Contact
                </Anchor>
              </Text>
              <Box direction="row" gap="small">
                <Anchor
                  size="small"
                  href={data?.sanitySite?.facebook}
                  target="_blank"
                >
                  <Svg>{FacebookIcon}</Svg>
                </Anchor>
                <Anchor
                  size="small"
                  href={data?.sanitySite?.youtube}
                  target="_blank"
                >
                  <Svg>{YoutubeIcon}</Svg>
                </Anchor>
                <Anchor
                  size="small"
                  href={data?.sanitySite?.instagram}
                  target="_blank"
                >
                  <Svg>{InstagramIcon}</Svg>
                </Anchor>
                <Anchor
                  size="small"
                  href={data?.sanitySite?.twitter}
                  target="_blank"
                >
                  <Svg>{TwitterIcon}</Svg>
                </Anchor>
              </Box>
            </Footer>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Layout
