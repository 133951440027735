import React, { forwardRef, RefObject } from "react"
import styled from "styled-components"

import { Box as BaseBox, BoxExtendedProps } from "grommet"

const CustomBox = styled(BaseBox)<BoxAditionalProps>`
  ${({ position }) => position !== undefined && "position: " + position + ";"}
  ${({ zIndex }) => zIndex !== undefined && "z-index: " + zIndex + ";"}
  ${({ top }) => top !== undefined && "top: " + top + ";"}
  ${({ left }) => left !== undefined && "left: " + left + ";"}
  ${({ bottom }) => bottom !== undefined && "bottom: " + bottom + ";"}
  ${({ right }) => right !== undefined && "right: " + right + ";"}
  ${({ offset }) =>
    offset !== undefined &&
    "transform: translateX(" + offset.x + ") translateY(" + offset.y + ");"}
`

type BoxAditionalProps = {
  position?: "relative" | "absolute"
  zIndex?: number
  top?: string
  left?: string
  bottom?: string
  right?: string
  offset?: { x: string; y: string }
}

type TProps = BoxAditionalProps & Omit<BoxExtendedProps, "css">

const Box = forwardRef(
  (
    { ...rest }: TProps,
    ref:
      | ((instance: HTMLDivElement | null) => void)
      | RefObject<HTMLDivElement>
      | null
  ): JSX.Element => {
    return <CustomBox ref={ref} {...rest} />
  }
)

export default Box
