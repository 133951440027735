import * as React from "react"
import SanityImage from "gatsby-plugin-sanity-image"
import { Anchor, Box, Text } from "grommet"
import { navigate } from "gatsby"
import styled from "styled-components"

import Svg from "@components/Svg"
import { NewTabIcon } from "@icons/index"

// prettier-ignore
const Container = styled(Box)`
  height: 72px;

  @media (min-width: ${({ theme }) => theme?.global?.breakpoints?.small?.value}px){
    height: 104px;
  }
`

type TProps = {
  logo: Queries.ImageFragment
  url?: string
}

const Header = ({
  logo,
  url = "https://www.channel4.com/programmes/river-cottage-reunited",
}: TProps): JSX.Element => {
  return (
    <Box
      as="header"
      border={{ side: "bottom", color: "grey-4" }}
      className="hor-section-pad"
      background="white"
      style={{ position: 'sticky', top: 0, zIndex: 2 }}
    >
      <Container
        fill="horizontal"
        direction="row"
        justify="between"
        align="center"
      >
        <Box fill="vertical" onClick={() => navigate("/")}>
          {logo && (
            <SanityImage
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                margin: 0,
              }}
              {...(logo as any)}
              alt="Logo"
            />
          )}
        </Box>

        <Box
          className="no-print desktop-only"
          justify="center"
          align="center"
          direction="row"
          gap="xxsmall"
        >
          <Text size="small" weight={500}>
            {`Watch all episodes at `}
            <Anchor href={url} target="_blank">
              channel4.com
            </Anchor>
          </Text>
          <Svg>{NewTabIcon}</Svg>
        </Box>
      </Container>
    </Box>
  )
}

export default Header
