import React, { ReactNode } from "react"
import theme from "@theming/theme"
import { Grommet as BaseGrommet } from "grommet"
import styled from "styled-components"

const Grommet = styled(BaseGrommet)`
  min-height: 100vh;
`

type TProps = {
  children: ReactNode
}

const Providers = ({ children }: TProps) => {
  return (
    <Grommet plain theme={theme}>
      {children}
    </Grommet>
  )
}

export default Providers
