import React from "react"
import { PropsOf } from "grommet/utils"

import Box from "@components/Box"

type TProps = PropsOf<typeof Box> & {
  horPad?: boolean
}

const Section = ({ children, horPad, ...rest }: TProps): JSX.Element => {
  return (
    <Box {...rest} direction="column" align="center">
      <Box
        width={{ max: "1440px" }}
        fill="horizontal"
        className={horPad ? "hor-section-pad" : ""}
      >
        {children}
      </Box>
    </Box>
  )
}

export default Section
